import React, { useRef } from "react";
import emailjs, { sendForm } from "@emailjs/browser";
import "./Contact.css";

interface ContactFormProps {}

const Contact: React.FC<ContactFormProps> = () => {
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_73ois76",
        "template_ge5rc9a",
        form.current!,
        "uGfww0MiLNitII4lb"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="form-container" id="Kontakt">
      <div className="form-kontakt">
        <img className="form-kontakt-logo" src="./logored.png" alt="" />
        <img
          className="form-kontakt-logo-tel"
          src="./logo-footer-tel.svg"
          alt=""
        />
        <h2 className="form-kontakt-hero">KONTAKT</h2>
        <p className="form-kontakt-img-text-tel">
          <img className="form-kontakt-img-tel" src="./tel.png" alt="" />
          577 132 200
        </p>
        <p className="form-kontakt-img-text-email">
          <img className="form-kontakt-img-email" src="./email.png" alt="" />
          contact@nha.com.pl
        </p>
      </div>
      <div className="form-kontakt-tel-all">
        <div className="form-kontakt-tel">
          <div className="form-info">
            <h2 id="Kontakt" className="form-kontakt-hero">
              KONTAKT
            </h2>
            <div className="form-kontakt-item">
              <p className="form-kontakt-img-text-tel">
                <img
                  className="form-kontakt-img-tel"
                  src="./tel.png"
                  alt="Phone Icon"
                />
                577 132 200
              </p>
            </div>
            <div className="form-kontakt-item">
              <img
                className="form-kontakt-img-email"
                src="./email.png"
                alt="Email Icon"
              />
              <p className="form-kontakt-img-text-email">
                m.szewczyk@nha.com.pl
              </p>
            </div>
          </div>
          <div
            className="form-logo-tel
        "
          >
            <img
              className="form-kontakt-logo-tel"
              src="./logo-footer-tel.svg"
              alt="Phone Icon"
            />
          </div>
        </div>
      </div>
      <div className="form-and-hero">
        <h1 className="form-hero-text">ZAPŁANUJ KONSULTACJĘ</h1>
        <form className="form" ref={form} onSubmit={sendEmail}>
          <input
            className="form-name-text"
            type="text"
            name="user_name"
            placeholder="Imię"
            required
          />
          <input
            className="form-tel-text"
            type="tel"
            name="user_tel"
            placeholder="Numer telefonu"
          />
          <input
            className="form-email-text"
            type="email"
            name="user_email"
            placeholder="E-mail"
          />
          <textarea
            className="form-message-text"
            name="message"
            placeholder="Komentarz"
          />
          <input className="form-submit" type="submit" value="WYŚLIJ" />
        </form>
        <p className="under-form-text">
          <span className="under-form-text-add">*</span> Przesyłając formularz,
          wyrażasz zgodę na przetwarzanie swoich danych osobowych w celach
          marketingowych przez New Home Agency.
        </p>
      </div>
    </div>
  );
};

export default Contact;
